// src/pages/account.js
import React from "react"
import { Router } from "@reach/router"
import { login, logout, isAuthenticated, getProfile } from "../utils/auth"
import NavBar from "../components/NavBar"
import { graphql } from "gatsby"
import { Container, Grid, Text, Box, Card, Divider, Button, Message } from "@theme-ui/components"
import { Link as GatsbyLink } from 'gatsby'
import { Link } from '@theme-ui/components'
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { navigate } from 'gatsby-link'
import { useState } from "react"
import Seo from "../components/seo"
import Footer from '../components/footer';
import {
  Label,
  Input,
  Select,
  Textarea,
  Radio,
  Checkbox,
  Slider,
} from 'theme-ui'

function encode(data) {
  return Object.keys(data)
  .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
  .join("&");
}


const Home = ({ user, posts }) => {
  const [state, setState] = React.useState({})
  console.log(user)
  return <Container>
  <Box py={4}>
    <Text py={3} as="h2">
      Courses for {user.name}
    </Text>
    <Grid columns={[3, null, 3]}>
  {posts.map(post => {
    if(post.frontmatter.contentCategory === 'Course') {
    return (
      <Card key={post.slug}
          sx={{
            maxWidth: '100%',
            padding: 1,
              borderRadius: 2,
              border: '1px solid',
              borderColor: 'primary',
          }}>
          <GatsbyImage image={getImage(post.frontmatter.featuredImage)}
          />
          <Text as="h3" p={2} sx={{
            color: 'secondary',
          }}>{post.frontmatter.title} - {post.frontmatter.subTitle}</Text>
          <Text as="p" p={2} sx={{
            color: 'text',
            fontSize: '12px',
          }}>{post.frontmatter.company}</Text>
          {post.frontmatter.message && <Message sx={{
                backgroundColor: 'background',
                borderColor: '#00ff00',
                color: 'text',
                marginBottom: '10px',
                borderTop: '1px solid #00ff00',
                borderBottom: '1px solid #00ff00',
                borderRight: '1px solid #00ff00',
                borderLeftWidth: '10px'}}>
                <Text as="p" sx={{
                  fontWeight: '700',
                  fontSize: '12px',
                  color: '#00ff00'
                }}>
                  {post.frontmatter.messages.map(info => {
                    return (
                      <>
                      {info.alertTitle} - {info.alertSubTitle} - {info.alertDate}
                      </>
                      )
                  })}
                </Text>
                <Divider my={1} sx={{
                  backgroundColor: '#00ff00',
                }}/>
                <Text as="p" sx={{
                  fontSize: '12px',
                }}>
                  {post.frontmatter.messages.map(info => {
                    return (
                      <>
                      {info.alertMessage}
                      </>
                      )
                  })}
                </Text>
                </Message>}
          <Text as="p" p={2} sx={{
            color: 'text',
          }}>{post.frontmatter.excerpt}</Text>
                <Link p={2} sx={{ color: 'primary' }} to={`/myame/course/${post.frontmatter.courseID}`} as={GatsbyLink}>
                  Start Course
                </Link>
          
          </Card>
    )
    }
  })}
  </Grid>
  </Box>
  </Container>
}

const Course = ({user, post}) => {
  return (
  <Container>
  <GatsbyImage image={getImage(post.frontmatter.featuredImage)}
          />
          {post.frontmatter.message && <Message my={4} sx={{
                backgroundColor: 'background',
                borderColor: '#00ff00',
                color: 'text',
                marginBottom: '10px',
                borderTop: '1px solid #00ff00',
                borderBottom: '1px solid #00ff00',
                borderRight: '1px solid #00ff00',
                borderLeftWidth: '10px'}}>
                <Text as="p" sx={{
                  fontWeight: '700',
                  fontSize: '12px',
                  color: '#00ff00'
                }}>
                  {post.frontmatter.messages.map(info => {
                    return (
                      <>
                      {info.alertTitle} - {info.alertSubTitle} - {info.alertDate}
                      </>
                      )
                  })}
                </Text>
                <Divider my={1} sx={{
                  backgroundColor: '#00ff00',
                }}/>
                <Text as="p" sx={{
                  fontSize: '12px',
                }}>
                  {post.frontmatter.messages.map(info => {
                    return (
                      <>
                      {info.alertMessage}
                      </>
                      )
                  })}
                </Text>
                </Message>}
    <h1>{post.frontmatter.title} - {post.frontmatter.subTitle}</h1>
    <Grid p={2} columns={[1, 1, 3]}>
      <Box p={2} bg="muted" sx={{
          color: 'secondary',
          fontWeight: '700',
          fontSize: '12px',
        }}>Category:<br />
        <Text sx={{
          color: 'text',
          fontWeight: '400',
        }}>{post.frontmatter.category}</Text>
        </Box>
        <Box p={2} bg="muted" sx={{
          color: 'secondary',
          fontWeight: '700',
          fontSize: '12px',
        }}>Company:<br />
        <Text sx={{
          color: 'text',
          fontWeight: '400',
        }}>{post.frontmatter.company}</Text>
        </Box>
        <Box p={2} bg="muted" sx={{
          color: 'secondary',
          fontWeight: '700',
          fontSize: '12px',
        }}>Instructors:<br />
        <Text sx={{
          color: 'text',
          fontWeight: '400',
        }}>{post.frontmatter.author}</Text>
        </Box>
        <Box p={2} bg="muted" sx={{
          color: 'secondary',
          fontWeight: '700',
          fontSize: '12px',
        }}>Course Length:<br />
        <Text sx={{
          color: 'text',
          fontWeight: '400',
        }}>{post.frontmatter.courseLength}</Text>
        </Box>
        <Box p={2} bg="muted" sx={{
          color: 'secondary',
          fontWeight: '700',
          fontSize: '12px',
        }}>Course Materials:<br />
        <Text sx={{
          color: 'text',
          fontWeight: '400',
        }}>{post.frontmatter.courseMaterials}</Text>
        </Box>
        <Box p={2} bg="muted" sx={{
          color: 'secondary',
          fontWeight: '700',
          fontSize: '12px',
        }}>Number of Modules:<br />
        <Text sx={{
          color: 'text',
          fontWeight: '400',
        }}>{post.frontmatter.modules.length}</Text>
        </Box>
      </Grid>
      <Divider sx={{
        backgroundColor: 'primary',
        marginBottom: '60px',
      }}
      />
    <p>{post.frontmatter.excerpt}</p>
    <Grid columns={[1, 1, 3]}>
    
    {post.frontmatter.modules.map(module => {
      return (

        <Card key={post.frontmatter.moduleID}
              sx={{
                maxWidth: '100%',
                padding: 1,
                  borderRadius: 2,
                  border: '1px solid',
                  borderColor: 'primary',
              }}>
              <Text as="p" px={2} py={0} sx={{color: 'primary', fontWeight: 'bold',  fontSize: '12px',}}>
              Module No. {module.moduleNumber} - {module.moduleID} - {module.moduleLength}
              </Text>
              <Link to={`/myame/course/${post.frontmatter.courseID}/module/${module.moduleID}`} as={GatsbyLink}>
              <Text as="h3" p={2} sx={{
                color: 'secondary',
              }}>{module.title}</Text>
              </Link>
              
              

              <Text as="p" p={2} sx={{
                color: 'text',
              }}>{module.content}</Text>
                <Link p={2} to={`/myame/course/${post.frontmatter.courseID}/module/${module.moduleID}`} as={GatsbyLink}>
                  <Button my={3}>Start Module</Button>
                </Link>
              
              </Card>

       
        
          
     
       

      )
    })}
    
    </Grid>
  </Container>
  )
  
}

const Module = ({user,courseID, moduleID, posts}) => {
  const organization = user['https://enterprise.ame.systems/org_name']
  console.log(user)
  
  const submitURL = '/myame/course/' + courseID
  const [state, setState] = React.useState({
    uid: user.email,
    org: organization,
    course: courseID,
    module: moduleID,
  })
  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value })
  }
  const handleSubmit = (e) => {
    e.preventDefault()
    const form = e.target
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...state,
      }),
    })
      .then(() => navigate(form.getAttribute('action')))
      .catch((error) => alert(error))
  }
  let displayCourseTitle = {}
  let displayModuleTitle = {}
  let displayModuleExcerpt = {}
  let displayModuleVideo = {}
  let displayModuleQuiz = {}

  {posts.map(post => {
    if (post.frontmatter.courseID === courseID) {
      displayCourseTitle = <h1>{post.frontmatter.title} - {post.frontmatter.subTitle}</h1>
      {post.frontmatter.modules.map(module => {
        if (module.moduleID === moduleID) {
          displayModuleTitle = <h2>Module {module.moduleNumber} - {module.moduleID} - {module.title}</h2>
          displayModuleExcerpt = <p>{module.content}</p>       
          displayModuleVideo = <div style={{padding:'56.25% 0 0 0', position:'relative'}}><iframe src={module.videoLink} frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style={{position:'absolute', top:'0', left:'0', width:'100%', height:'100%'}} title={moduleID}></iframe></div>
          
          
          displayModuleQuiz = <Box py={4} as="form"
          name="ame-module-quiz"  
          method="post"
          action={submitURL}
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          onSubmit={handleSubmit}
          >
          <Text sx={{
            visibility: 'hidden',
          }}>
            <label>Don’t fill this out if you’re human: <input name="bot-field" /></label>
          </Text>
          <h2>{module.title} - Quiz</h2>
          <Divider sx={{
            backgroundColor: 'primary',
            marginBottom: '40px',
          }} />
          <Box p={2} sx={{
            backgroundColor: 'muted',
          }}>
          <Text py={2} as="h3"sx={{
            color: 'secondary',
          }}>
            Rate This Module
          </Text>
          <Grid columns={[1,1,5]}>
          <Label>
            <Radio
              name='rating'
              value='1'
              onChange={handleChange}
              required
            />
            Very Poor
          </Label>
          <Label>
            <Radio
              name='rating'
              value='2'
              onChange={handleChange}
              required
            />
            Poor
          </Label>
          <Label>
            <Radio
              name='rating'
              value='3'
              onChange={handleChange}
              required
            />
            Acceptable
          </Label>
          <Label>
            <Radio
              name='rating'
              value='4'
              onChange={handleChange}
              required
            />
            Good
          </Label>
          <Label>
            <Radio
              name='rating'
              value='5'
              onChange={handleChange}
              required
            />
            Very Good
          </Label>
          </Grid>
          </Box>

          <Input sx={{opacity: '0', maxHeight: '1px'}} type="text" name="uid" id="uid" value={user.email} onChange={handleChange} required/>
          <Input sx={{opacity: '0', maxHeight: '1px'}} type="text" name="org" id="org" value={organization} onChange={handleChange} required/>
          <Input sx={{opacity: '0', maxHeight: '1px'}} type="text" name="course" id="course" value={courseID} onChange={handleChange} required/>
          <Input sx={{opacity: '0', maxHeight: '1px'}} type="text" name="module" id="module" value={moduleID} onChange={handleChange} required/>
          <Input type="hidden" name="form-name" value="ame-module-quiz"/>
          <Grid columns={[1,1,2]}>
          {module.quiz.map(question => {
            return (
              
              <Box p={2} sx={{
                backgroundColor: 'muted',
              }}>
              <Text py={2} as="h3" sx={{
                color: 'secondary',
              }}>
                {question.question}
              </Text>
              {question.choices.map(answer => {
                return (
                  <Label>
                    <Radio
                      name={question.qnumber}
                      value={answer === question.correctAnswer ? 'correct' : 'incorrect'}
                      onChange={handleChange}
                      required
                    />
                    {answer}
                  </Label>
                )
              })}
              </Box>
            )
          })}
          
          </Grid>
  
          <Button my={3} as="button" type="submit">Submit Quiz</Button>
        </Box>
        }
      })}
    }
  }
  )}
  return (
  <Container>
  {displayCourseTitle}
  {displayModuleTitle}
  {displayModuleExcerpt}
  {displayModuleVideo}
  {displayModuleQuiz}
  </Container>
  )
}

const Myame = ({ data }) => {
  const posts = data.allMdx.nodes
  

  if (!isAuthenticated()) {
    login()
    return <p>Redirecting to login...</p>
  }

  const user = getProfile()
  const organization = user['https://enterprise.ame.systems/org_name']

  console.log(user.email)
  return (
    <>
    <Seo />
      <NavBar />



  <Router>
  <Home path="/myame/" posts={posts} user={user}/>
  <Module path="/myame/course/:courseID/module/:moduleID" user={user} posts={posts}/>
  {posts.map(post => {
    const modules = post.frontmatter.modules;

    console.log(modules)
    return (
      <Course path={`/myame/course/${post.frontmatter.courseID}`} courseID={post.frontmatter.courseID} courseTitle={post.frontmatter.title} courseSubTitle={post.frontmatter.subTitle} courseFeatureImage={post.featuredImage} courseExcerpt={post.frontmatter.excerpt} courseModules={post.frontmatter.modules} post={post} />
    )
      
  })}

  </Router>
  <Footer />
    </>
  )
}

export default Myame

export const pageQuery = graphql`
  query {
    allMdx(sort: {fields: [frontmatter___date], order: DESC}) {
      nodes {
        id
        slug
        excerpt(pruneLength: 250)
        frontmatter {
          category
          excerpt
          author
          contentCategory
          company
          courseID
          courseMaterials
          courseLength
          featuredImage {
            childImageSharp {
              gatsbyImageData(
                width: 1200
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
          title
          subTitle
          message
          messages {
            alertMessage
            alertTitle
            alertSubTitle
            alertDate
          }
          date(formatString: "YYYY MMMM Do")
          modules {
            moduleID
            moduleNumber
            moduleLength
            title
            content
            videoLink
            quiz {
              question
              qnumber
              choices
              correctAnswer
            }
          }
        }
      }
    }
  }
`